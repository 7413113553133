import videoBg from '../assets/Backgrounds/videoBg.mp4'

const Hero = () => {
  return (
    <section className="hero-wrapper">
      <div className="video-container">
        <div className="content">
          <video src={videoBg} autoPlay muted />
        </div>
        <div className="hero">
          <h3 >Hi, I&#39;m</h3>
          <h1>Kate Shea</h1>
          {/* <h2 className="hero-large hero-green">full stack developer | kd shea web design</h2>
          <h2 className="hero-small hero-green">full stack dev <br /> kd shea web design</h2> */}
          <h2>Building good things for good people.</h2>
          {/* <div className="hero-arrow">
            <a href="#consultation">
              <p>new freelance clients + collabs</p>
              <i className="fa-solid fa-chevron-down"></i>
            </a>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default Hero